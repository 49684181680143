import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Link, User } from './data.model';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LinksService {

  private user: User;

  private linksCollection: AngularFirestoreCollection<Link>;

  constructor(private fireStore: AngularFirestore, private auth: AuthService) {
    this.linksCollection = this.fireStore.collection<Link>('links');
  }

  getLinks() {
    return this.linksCollection.valueChanges()
  }

  getLink(id: string) {
    return this.linksCollection.doc<Event>(id).valueChanges();
  }

  addLink(link: Link) {
    return this.linksCollection.add(link);
  }

  deleteLink(id: string) {
    return this.linksCollection.doc(id).delete();
  }

}

import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { StatusBar } from '@ionic-native/status-bar/ngx';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  darkTheme: boolean;

  constructor(
    private storage: Storage,
    private statusBar: StatusBar
  ) {
    this.darkTheme = false;
  }

  initTheme() {
    this.storage.get('darkTheme').then(result => {
      if (result === null) {
        this.setTheme(false);
        return;
      }
      this.darkTheme = result;
      this.setStatusBar(result);
    });
  }

  setTheme(isDarkTheme: boolean) {
    this.storage.set('darkTheme', isDarkTheme).then(result => {
      this.darkTheme = result;
      this.setStatusBar(result);
    });
  }

  setStatusBar(isDarkTheme: boolean) {
    if (isDarkTheme) {
      this.statusBar.styleLightContent();
    } else {
      this.statusBar.styleDefault();
    }
  }

}

import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AboutConfig, DirectorInfo, HouseGroupConfig } from './data.model';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  private aboutConfig: AngularFirestoreDocument<AboutConfig>;
  private directorInfo: AngularFirestoreDocument<DirectorInfo>;
  private houseGroupConfig: AngularFirestoreDocument<HouseGroupConfig>;

  constructor(private fireStore: AngularFirestore) {
    this.aboutConfig = this.fireStore.collection('app-config').doc<AboutConfig>('about');
    this.directorInfo = this.fireStore.collection('app-config').doc<DirectorInfo>('director_info');
    this.houseGroupConfig = this.fireStore.collection('app-config').doc<HouseGroupConfig>('house_group');
  }

  getAboutConfig() {
    return this.aboutConfig.valueChanges()
  }

  getDirectorInfo() {
      return this.directorInfo.valueChanges()
  }

  getHouseGroupConfig() {
    return this.houseGroupConfig.valueChanges();
  }

}

import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Feedback, User } from './data.model';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdminToolsService {

  private userCollection: AngularFirestoreCollection<User>;
  private users: Observable<User[]>;
  private feedbackCollection: AngularFirestoreCollection<Feedback>;
  private feedback: Observable<Feedback[]>;

  constructor(
    private afs: AngularFirestore
  ) {
    this.userCollection = this.afs.collection<User>('users', ref => ref.orderBy('firstName'));
    this.users = this.userCollection.valueChanges();
    this.feedbackCollection = this.afs.collection<Feedback>('feedback');
    this.feedback = this.feedbackCollection.valueChanges();
  }

  getUsers() {
    return this.users;
  }

  getUser(uid: string) {
    return this.userCollection.doc<User>(uid).valueChanges();
  }

  addFeedback(feedback: Feedback) {
    return this.feedbackCollection.add(feedback);
  }

  async getMemoryAccessReason(userId: string): Promise<string> {
    const request: any = await this.afs.collection('access-requests').doc(userId).valueChanges().pipe(take(1)).toPromise();
    return request.reason;
  }

  grantMemoryAccess(userId: string) {
    return this.afs.collection('users').doc(userId).update({
      allowMemories: true,
      memoryStatus: ''
    });
  }

  denyMemoryAccess(userId: string) {
    return this.afs.collection('users').doc(userId).update({
      allowMemories: false,
      memoryStatus: 'denied'
    });
  }

}

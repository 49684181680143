import { Injectable } from '@angular/core';
import { Firebase } from '@ionic-native/firebase/ngx';
import { Notifications } from './data.model';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(
    private notif: Firebase,
    private storage: Storage
  ) { }

  checkPermission() {
    this.notif.hasPermission().then(res => {
      if (res.isEnabled) {
        this.initializeNotifications(true);
      } else {
        this.notif.grantPermission().then(() => {
          this.initializeNotifications(true);
        });
      }
    }, err => {
      console.log(err);
    });
  }

  initializeNotifications(value: boolean) {
    this.storage.get('notifications').then(res => {
      if (!res) {
        const notifications: Notifications = {
          clubReminder: value,
          houseGroupReminder: value,
          bibleStudyReminder: value,
          eventReminder: value,
        };
        this.storage.set('notifications', notifications).then(() => { })
          .catch((err: Error) => {
            console.log(err);
          });
        this.changeSubscription('clubReminder', value);
        this.changeSubscription('houseGroupReminder', value);
        this.changeSubscription('bibleStudyReminder', value);
        this.changeSubscription('eventReminder', value);
      }
    });
  }

  changeSubscription(topic: string, doSubscribe: boolean) {
    if (doSubscribe) {
      this.notif.subscribe(topic)
        .then()
        .catch((err: Error) => {
          console.log(`Could not subscribe to ${topic}, received: ${err}`);
        });
    } else {
      this.notif.unsubscribe(topic)
        .then()
        .catch((err: Error) => {
          console.log(`Could not unsubscribe from ${topic}, received: ${err}`);
        });
    }
  }

}

import { Injectable } from '@angular/core';
import { EmailComposer } from '@ionic-native/email-composer/ngx';
import { Platform, AlertController, ToastController, LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(
    private emailComp: EmailComposer,
    private platform: Platform,
    private alertController: AlertController,
    private toastController: ToastController,
    private loadingController: LoadingController
  ) { }

  /**
   * Send an email to support
   */
  emailSupport() {
    this.sendEmail('mitchellrust@gmail.com', 'YL Boise State App Support');
  }

  /**
   * Send an email
   * On iOS open the email composer
   * Default mailto: behavior everywhere else
   */
  sendEmail(to: string, subject: string = '') {
    if (this.platform.is('ios')) {
      this.emailComp.open({ to, subject });
    } else {
      window.open('mailto:' + to, '_system');
    }
  }

  /**
   * Call a phone number
   */
  call(number: number) {
    window.open(`tel:${number}`, '_system');
  }

  /**
   * Text a phone number
   */
  text(number: number) {
    window.open(`sms:${number}`, '_system');
  }

  /**
   * Given a header and a message, show an alert with only one button: OK
   */
  async showAlert(header: string, message: string) {
    const alert = await this.alertController.create({
      header,
      message,
      buttons: ['OK']
    });
    return await alert.present();
  }

  /**
   * Given a url, confirm the url is okay with leaving the application
   * then open the link
   */
  async confirmAndGo(url: string) {
    const alert = await this.alertController.create({
      header: 'Open in browser?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: 'Open',
          handler: () => {
            this.openLink(url);
          }
        }
      ]
    });

    await alert.present();
  }

  /**
   * Open a given url in the default browser
   */
  openLink(url: string) {
    window.open(url, '_system', 'location=yes');
  }

  /**
   * Show a given message in a toast
   */
  async presentToast(message: string) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000,
      color: 'dark'
    });
    await toast.present();
  }

  /**
   * Present a loader with the given message, then return the loader
   * so the caller can dismiss it with loading.dismiss();
   * @param message 
   */
  async presentLoader(message: string) {
    const loading = await this.loadingController.create({ message });
    await loading.present();
    return loading;
  }

  /**
   * Formats any US phone number to a pretty printed text
   * @param phone the phone number to be formatted, as a string
   */
  formatPhoneNumber(phone: string) {
    if (phone.length > 11 || phone.length < 10) {
      return "";
    }

    if (phone.length === 11 && !phone.startsWith("1")) {
      return "";
    }

    if (phone.length === 11) {
      return `+1 (${phone.substring(1, 4)}) ${phone.substring(4, 7)}-${phone.substring(7)}`;
    }

    return `(${phone.substring(0, 3)}) ${phone.substring(3, 6)}-${phone.substring(6)}`;
  }
}

import { Component, OnInit } from '@angular/core';
import { ModalController, AlertController, LoadingController } from '@ionic/angular';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.page.html',
  styleUrls: ['./password-reset.page.scss'],
})
export class PasswordResetPage implements OnInit {

  email: string;

  constructor(
    private alertController: AlertController,
    private modalController: ModalController,
    private loadingController: LoadingController,
    private afAuth: AngularFireAuth
  ) { }

  ngOnInit() {
  }

  closeModal() {
    this.modalController.dismiss();
  }

  async showAlert(header: string, message: string) {
    const alert = await this.alertController.create({
      header,
      message,
      buttons: ['OK']
    });
    return await alert.present();
  }

  async sendResetCode() {
    if (!this.email) {
      this.showAlert('Invalid Email', 'Please enter a valid email address.');
      return;
    }
    const loading = await this.loadingController.create({
      message: 'Please wait...',
      spinner: 'crescent',
    });
    loading.present();
    this.afAuth.auth.sendPasswordResetEmail(this.email).then(() => {
      loading.dismiss().then(() => {
        this.showAlert('Reset Link Sent', 'Check your email for a link to reset your password.')
          .catch(error => console.log(error));
        this.closeModal();
      });
    }).catch(err => {
      loading.dismiss().then(() => {
        const errorCode = err.code;
        const errorMessage = err.message;
        if (errorCode === 'auth/invalid-email') {
          this.showAlert('Invalid Email', 'Please enter a valid email address.')
            .catch(error => console.log(error));
        } else if (errorCode === 'auth/user-not-found') {
          this.showAlert('Account Not Found', 'An account with that email address does not exist.')
            .catch(error => console.log(error));
        } else {
          this.showAlert('An Error Occurred', 'An unknown error occurred. Please check your network connection and try again.')
            .catch(error => console.log(error));
          console.log(errorMessage);
        }
      });
    });
  }

}

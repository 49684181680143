import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { EditPageModule } from './events/edit/edit.module';
import { PasswordResetPageModule } from './more/login/password-reset/password-reset.module';
import { EditNotificationPageModule } from './events/edit/edit-notification/edit-notification.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';

import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { Firebase } from '@ionic-native/firebase/ngx';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { AuthService } from './services/auth.service';
import { EventsService } from './services/events.service';
import { LinksService } from './services/links.service';
import { AppConfigService } from './services/app-config.service';
import { AdminToolsService } from './services/admin-tools.service';
import { EmailComposer } from '@ionic-native/email-composer/ngx';
import { IonicStorageModule } from '@ionic/storage';
import { LaunchNavigator } from '@ionic-native/launch-navigator/ngx';

import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { ThemeableBrowser } from '@ionic-native/themeable-browser/ngx';
// import { WebView } from '@ionic-native/ionic-webview/ngx';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    EditPageModule,
    EditNotificationPageModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    PasswordResetPageModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    ScreenOrientation,
    GooglePlus,
    EmailComposer,
    AuthService,
    Firebase,
    EventsService,
    LinksService,
    AppConfigService,
    AdminToolsService,
    InAppBrowser,
    LaunchNavigator,
    ThemeableBrowser,
    // WebView,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

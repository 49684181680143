// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyAxaxhFPt33Th2ch7F7s5tYTcKETdOlcSU",
    authDomain: "genesis-dev-7c442.firebaseapp.com",
    databaseURL: "https://genesis-dev-7c442.firebaseio.com",
    projectId: "genesis-dev-7c442",
    storageBucket: "genesis-dev-7c442.appspot.com",
    messagingSenderId: "887933606604",
    appId: "1:887933606604:web:f87e96971efa4bbf8bc069"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { ThemeService } from './services/theme.service';
import { NotificationsService } from './services/notifications.service';
import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {

  constructor(
    public theme: ThemeService,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private screenOrientation: ScreenOrientation,
    private notif: NotificationsService,
    private storage: Storage,
    private router: Router
  ) {
    this.onboardingCheck();
    this.initializeApp();
  }

  async onboardingCheck() {
    this.storage.get('accessed').then((val) => {
      // enter onboarding
      if (!val) {
        this.storage.set('accessed', true);
        this.router.navigate(['onboarding']);
      }
    });
  }


  initializeApp() {
    this.platform.ready().then(() => {
      // Set Light or Dark Theme
      this.theme.initTheme();

      if (this.platform.is('cordova')) {
        // Check if registered for push notifications
        this.notif.checkPermission();
        // Lock screen in portrait mode
        this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
        // Enter Home Screen
        this.splashScreen.hide();
      }
    });
  }
}

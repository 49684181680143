import { Component, OnInit, OnDestroy } from '@angular/core';
import { ModalController, AlertController, NavController } from '@ionic/angular';
import { EventsService } from '../../services/events.service';
import { Event } from '../../services/data.model';
import { EditNotificationPage } from './edit-notification/edit-notification.page';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.page.html',
  styleUrls: ['./edit.page.scss'],
})
export class EditPage implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();

  limitCapacity = false;

  event: Event = {
    name: null,
    startDate: null,
    startString: null,
    endDate: null,
    locationName: null,
    address: null,
    description: null,
    hostName: null,
    hostPhoneNumber: null,
    notification: {
      topic: null,
      title: null,
      message: null,
      sendDate: null,
      link: null,
    },
    leadersOnly: null,
    capacity: null,
  };

  startTime = null;
  eventId;
  headingTitle;
  minDate;
  maxDate;
  multiDayEvent: boolean;

  constructor(private modalController: ModalController,
    private eventsService: EventsService,
    private alertController: AlertController,
    private navController: NavController,
    private util: UtilService
  ) {
    this.headingTitle = 'Add Event';
    this.multiDayEvent = false;
    const today = new Date();
    const year = today.getFullYear();

    this.minDate = year;
    this.maxDate = String(year + 1);
  }

  ngOnInit() {
    if (this.eventId) {
      this.headingTitle = 'Edit Event';
      this.loadEvent();
    }
  }

  loadEvent() {
    this.eventsService.getEvent(this.eventId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(res => {
        this.event = res;
        this.startTime = this.event.startDate;
        if (res.endDate !== null) {
          this.multiDayEvent = true;
        }

        // set the toggle to on if capacity is set
        this.limitCapacity = !!res.capacity;
      });
  }

  async showAlert(requirements: string) {
    const alert = await this.alertController.create({
      header: 'More Info',
      message: `The following fields are required:${requirements}`,
      buttons: ['OK']
    });
    return await alert.present();
  }

  checkMultiDay(e) {
    this.multiDayEvent = e.detail.checked;
    if (this.multiDayEvent) {
      this.event.endDate = this.event.startDate;
    } else {
      this.event.endDate = null;
    }
  }

  verifyData(): string {
    let list = '<ul>';
    if (!this.event.name) {
      list += '<li>Title</li>';
    }
    if (!this.event.startDate) {
      list += '<li>Start Date</li>';
    }
    if (!this.startTime) {
      list += '<li>Start Time</li>';
    }
    if (!this.event.locationName) {
      list += '<li>Location Name</li>';
    }
    list += '</ul>';
    return list;
  }

  saveEvent() {
    // verify all required fields are present
    const requirements = this.verifyData();
    if (!requirements.includes('<ul></ul>')) {
      this.showAlert(requirements);
      return;
    }

    // check capacity
    if (!this.limitCapacity) {
      this.event.capacity = null;
    }

    // date logic
    this.event.startString = this.eventsService.getStartDate(this.event.startDate);
    this.event.startDate = this.event.startDate.substring(0, 11)
      + this.startTime.substring(11, 19) + this.event.startDate.substring(19);

    // update existing event
    if (this.eventId) {
      try {
        this.eventsService.updateEvent(this.event, this.eventId).then(() => {
          this.util.presentToast('Event updated.');
          this.closeModal();
        });
      } catch (err) {
        console.log(err);
      }
    } 
    
    // new event
    else {
      try {
        this.eventsService.addEvent(this.event).then(() => {
          this.util.presentToast('Event created.');
          this.closeModal();
        });
      } catch (err) {
        console.log(err);
      }
    }
  }

  deleteEvent() {
    this.eventsService.deleteEvent(this.eventId).then(() => {
      this.util.presentToast('Event deleted.');
      this.closeModal();
      this.navController.back();
    });
  }

  async confirmDelete() {
    const alert = await this.alertController.create({
      header: 'Are you sure?',
      message: 'This action cannot be undone.',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        }, {
          text: 'Delete',
          role: 'destructive',
          handler: () => {
            this.deleteEvent();
          }
        }
      ]
    });
    return await alert.present();
  }

  deleteNotification() {
    this.event.notification = {
      topic: null,
      title: null,
      message: null,
      sendDate: null,
      link: null,
    };
  }

  async notificationModal() {
    const modal = await this.modalController.create({
      component: EditNotificationPage,
      componentProps: {
        beforeNotification: this.event.notification
      }
    });

    modal.onDidDismiss().then((data) => {
      if (data.data) {
        this.event.notification = data.data;
      }
    });

    return await modal.present();
  }

  closeModal() {
    this.modalController.dismiss();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

}

import { Injectable, OnInit, EventEmitter } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { User } from './data.model';
import { Storage } from '@ionic/storage';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnInit {
  user: User;
  authUser: any = {};

  userChanged$: EventEmitter<void>;

  constructor(
    private afAuth: AngularFireAuth,
    private firestore: AngularFirestore,
    private storage: Storage
  ) {
    this.userChanged$ = new EventEmitter();
    this.afAuth.authState.pipe(
      switchMap(user => {
        this.authUser = user;

        // Logged in
        if (user) {
          return this.firestore.doc<User>(`users/${user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      })
    ).subscribe(user => {
      this.user = user;
      this.storage.set('user', user);
      this.userChanged$.emit();
    });
  }

  get userId(): string {
    return (this.user ? this.user.uid : '') || '';
  }

  async ngOnInit() {
    this.user = _.extend(await this.storage.get('user'), this.user);
  }

  async logInUser(email: string, password: string) {
    await this.afAuth.auth.setPersistence('local').catch(error => {
      console.log(error);
    });
    let err;
    await this.afAuth.auth.signInWithEmailAndPassword(email, password).catch(error => {
      err = error;
    });
    if (this.afAuth.auth.currentUser && this.afAuth.auth.currentUser.emailVerified) {
      err = null;
    } else if (this.afAuth.auth.currentUser) {
      err = new Error('User email has not been verified.');
      err.code = 'custom/not-verified';
    } else if (err === null) {
      err = new Error('An unknown error occured.');
      err.code = 'custom/unknown';
    }
    return err;
  }

  async signUpUser(user: User, password: string) {
    await this.afAuth.auth.setPersistence('local').catch(error => {
      console.log(error);
    });
    let err;
    await this.afAuth.auth.createUserWithEmailAndPassword(user.email, password).then(cred => {
      const userID = cred.user.uid;
      this.createUserDocument(userID, user);
      this.afAuth.auth.currentUser.sendEmailVerification().catch(error => {
        err = error;
      });
      err = null;
    }).catch(error => {
      err = error;
    });
    return err;
  }

  async createUserDocument(userID: string, user: User) {
    const userRef: AngularFirestoreDocument<User> = this.firestore.doc(`users/${userID}`);
    const data: User = {
      uid: userID,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      roles: {
        user: true
      }
    };
    return userRef.set(data);
  }

  async signOut() {
    await this.afAuth.auth.signOut();
  }

  canEdit(): boolean {
    const allowed = ['admin', 'editor'];
    return this.checkAuthorization(this.user, allowed);
  }

  isAdmin(): boolean {
    const allowed = ['admin'];
    return this.checkAuthorization(this.user, allowed);
  }

  isLeader(): boolean {
    const allowed = ['leader', 'editor', 'admin'];
    return this.checkAuthorization(this.user, allowed);
  }

  isHouseGroupAdmin(): boolean {
    const allowed = ['maleHouseGroupAdmin', 'femaleHouseGroupAdmin'];
    return this.checkAuthorization(this.user, allowed);
  }

  isMaleHouseGroupAdmin(): boolean {
    const allowed = ['maleHouseGroupAdmin'];
    return this.checkAuthorization(this.user, allowed);
  }

  isFemaleHouseGroupAdmin(): boolean {
    const allowed = ['femaleHouseGroupAdmin'];
    return this.checkAuthorization(this.user, allowed);
  }

  emailVerified(): boolean {
    return this.authUser.emailVerified === true;
  }

  // determines if user has matching role
  private checkAuthorization(user: User, allowedRoles: string[]): boolean {
    if (!user) { return false; }
    for (const role of allowedRoles) {
      if (user.roles[role]) {
        return true;
      }
    }
    return false;
  }

}

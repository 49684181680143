import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentReference } from '@angular/fire/firestore';
import { Event, User } from './data.model';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  private user: User;

  private eventsCollection: AngularFirestoreCollection<Event>;
  private events: Observable<Event[]>;

  private weekdays = new Array(7);
  private months = new Array(12);

  constructor(
    private afs: AngularFirestore, 
    private auth: AuthService,
  ) {
    this.eventsCollection = this.afs.collection<Event>('events', ref => ref.orderBy('startDate'));
    this.events = this.eventsCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          const displayDate = this.getStartDate(data.startDate);
          return { id, displayDate, ...data };
        });
      })
    );
  }

  getEvents() {
    return this.events;
  }

  getEvent(id: string) {
    return this.eventsCollection.doc<Event>(id).valueChanges();
  }

  addEvent(event: Event) {
    return this.eventsCollection.add(event);
  }

  updateEvent(event: Event, id: string) {
    return this.eventsCollection.doc(id).update(event);
  }

  deleteEvent(id: string) {
    return this.eventsCollection.doc(id).delete();
  }

  getStartDate(dateString: string, includeTime?: boolean) {
    let returnString = '';
    const date = moment(dateString);
    const now = moment();
    const howFar = this.roundDown(date).diff(this.roundDown(now), 'days');
    
    // format today, tomorrow, and Monday, Tuesday, etc for other days this week
    // fall back to Monday, Aug 18 as a backup format
    if (howFar === 0) returnString += 'Today';
    else if (howFar === 1) returnString += 'Tomorrow';
    else if (howFar < 7) returnString += date.format('dddd');
    else returnString += date.format('dddd, MMM DD');

    // for events this week, default to showing time too
    if (howFar < 7 && includeTime === undefined) includeTime = true;

    // if includeTime, append 8:28pm or similar to the end
    if (includeTime) returnString += ` at ${date.format('h:mma')}`;

    return returnString;
  }

  roundDown(someMoment) {
    return moment(someMoment.format('MM/DD/YYYY'), 'MM/DD/YYYY');
  }

  joinEvent(userId: string, eventId: string) {
    return this.eventsCollection.doc(eventId).update({
      registered: firebase.firestore.FieldValue.arrayUnion(userId)
    });
  }

  removeFromEvent(userId: string, eventId: string) {
    return this.eventsCollection.doc(eventId).update({
      registered: firebase.firestore.FieldValue.arrayRemove(userId)
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Notification } from '../../../services/data.model';
import * as _ from 'lodash';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'app-edit-notification',
  templateUrl: './edit-notification.page.html',
  styleUrls: ['./edit-notification.page.scss'],
})
export class EditNotificationPage implements OnInit {

  // passed in through componentProps from edit.page.ts
  private beforeNotification: Notification;

  // the notification that is rendered in the modal
  notification: Notification;

  sendTime = null;

  constructor(
    private modalController: ModalController,
    private alertController: AlertController
  ) { }

  ngOnInit(): void {
    // take and clone the existing notification, passed in from componentProps
    // as beforeNotification. Or default to a new empty notification
    this.notification = _.cloneDeep(this.beforeNotification || {
      topic: null,
      title: null,
      message: null,
      sendDate: null,
      link: null,
    });

    if (this.notification.title) {
      this.sendTime = this.notification.sendDate;
    }
  }

  topicChange($event) {
    this.notification.topic = $event.detail.value;
    if (this.notification.topic === 'clubReminder') {
      this.notification.title = 'Club Reminder';
    } else if (this.notification.topic === 'houseGroupReminder') {
      this.notification.title = 'House Group Reminder';
    } else if (this.notification.topic === 'bibleStudyReminder') {
      this.notification.title = 'Co-Ed Bible Study Reminder';
    } else if (this.notification.topic === 'eventReminder') {
      this.notification.title = 'Event Reminder';
    } else {
      this.notification.title = 'Leader Notification';
    }
  }

  async showAlert(requirements: string) {
    const alert = await this.alertController.create({
      header: 'More Info',
      message: `The following fields are required:${requirements}`,
      buttons: ['OK']
    });
    return await alert.present();
  }

  verifyData(): string {
    let list = '<ul>';
    if (!this.notification.topic) {
      list += '<li>Topic</li>';
    }
    if (!this.notification.message) {
      list += '<li>Message</li>';
    }
    if (!this.notification.sendDate) {
      list += '<li>Send Date</li>';
    }
    if (!this.sendTime) {
      list += '<li>Send Time</li>';
    }
    list += '</ul>';
    return list;
  }

  saveNotification() {
    const requirements = this.verifyData();
    if (!requirements.includes('<ul></ul>')) {
      this.showAlert(requirements);
      return;
    }
    this.notification.sendDate = this.notification.sendDate.substring(0, 11)
      + this.sendTime.substring(11, 19) + this.notification.sendDate.substring(19);
    this.notification.sent = false;
    this.modalController.dismiss(this.notification);
  }

  closeModal() {
    this.modalController.dismiss(this.beforeNotification);
  }

}
